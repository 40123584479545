/* eslint-disable default-case */
import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

// import { Row, Item } from '@mui-treasury/components/flex';
// import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
// import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor';
// import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
// import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

// forms
import { UKasa } from 'layouts/projects/Forms/UKasa/UKasaForm';
import { Kasa } from 'layouts/projects/Forms/Kasa/KasaForm';
// import { KasaFormNew } from 'layouts/projects/Forms/Kasa/KasaFormNew';
import { Kanat } from 'layouts/projects/Forms/Kanat/KanatForm';
import { UPervaz } from 'layouts/projects/Forms/UPervaz/UPervazForm';
import { Pervaz } from 'layouts/projects/Forms/Pervaz/PervazForm';
import { Diger } from 'layouts/projects/Forms/Diger/DigerForm';
import Template from 'layouts/projects/Forms/Templates';
import SheetTemplates from 'layouts/projects/Forms/SheetTemplates';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

import FormCard from 'examples/Cards/ProjectCards/FormCard';

import { config } from 'Constants';
import { isSuperAdmin } from 'helpers/roleHelpers';

import useDialogHook from 'hooks/useDialogHook';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 1000,
    maxHeight: 800,
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
};
export default function AllForms(props) {
    const { demand, projectID } = props;
    const [open, setOpen] = useState(false);
    const [activeItemType, setActiveItemType] = React.useState('');
    const user = JSON.parse(localStorage.getItem('user'));
    const {
        open: openTemplate,
        handleClickOpen: handleClickOpenTemplate,
        handleClose: handleCloseTemplate,
    } = useDialogHook();

    const {
        open: openSheetTemplates,
        handleClickOpen: handleClickOpenSheetTemplates,
        handleClose: handleCloseSheetTemplates,
    } = useDialogHook();
    const postItem = (activeItemType) => {
        switch (activeItemType) {
            case 'uKasa':
                setActiveItemType('uKasa');
                setOpen(true);
                break;
            case 'kasa':
                setActiveItemType('kasa');
                setOpen(true);
                break;
            case 'kanat':
                setActiveItemType('kanat');
                setOpen(true);
                break;
            case 'uPervaz':
                setActiveItemType('uPervaz');
                setOpen(true);
                break;
            case 'pervaz':
                setActiveItemType('pervaz');
                setOpen(true);
                break;
            case 'diger':
                setActiveItemType('diger');
                setOpen(true);
                break;
        }
    };
    const activeForm = () => {
        switch (activeItemType) {
            case 'uKasa':
                return <UKasa demandID={demand._id} setOpen={setOpen} projectID={projectID} />;
            case 'kasa':
                return <Kasa demandID={demand._id} setOpen={setOpen} projectID={projectID} />;
            case 'kanat':
                return <Kanat demandID={demand._id} setOpen={setOpen} projectID={projectID} />;
            case 'uPervaz':
                return <UPervaz demandID={demand._id} setOpen={setOpen} projectID={projectID} />;
            case 'pervaz':
                return <Pervaz demandID={demand._id} setOpen={setOpen} projectID={projectID} />;
            case 'diger':
                return <Diger demandID={demand._id} setOpen={setOpen} projectID={projectID} />;
        }
    };
    return (
        <MDBox>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>{activeForm()}</Box>
                </Fade>
            </Modal>
            <MDBox
                mt={5}
                color="white"
                bgColor="light"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image={config.images.uKasa}
                                title="U KASA"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={() => postItem('uKasa')}
                                    >
                                        YENİ EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image={config.images.uPervaz}
                                title="U PERVAZ"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={() => postItem('uPervaz')}
                                    >
                                        YENİ EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image={config.images.kanat}
                                title="KANAT"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={() => postItem('kanat')}
                                    >
                                        YENİ EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image={config.images.kaplamaliKasa}
                                title="STD. KASA"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={() => postItem('kasa')}
                                    >
                                        YENİ EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image={config.images.kaplamaliPervaz}
                                title="STD. PERVAZ"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={() => postItem('pervaz')}
                                    >
                                        YENİ EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image={config.images.boya}
                                title="DİĞER"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={() => postItem('diger')}
                                    >
                                        YENİ EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={isSuperAdmin(user) ? 6 : 12} lg={isSuperAdmin(user) ? 6 : 12}>
                        <MDBox mb={1.5} mt={1.5}>
                            <FormCard
                                image="https://kapim.com.tr/wp-content/uploads/2022/11/vibe-3-2048x2048.png"
                                title="Şablon ile kapı seti ekle"
                                dateTime={
                                    <MDButton
                                        variant="gradient"
                                        color="dark"
                                        size="small"
                                        onClick={handleClickOpenTemplate}
                                    >
                                        YENİ SET EKLE
                                    </MDButton>
                                }
                                color="dark"
                            />
                        </MDBox>
                    </Grid>
                    {isSuperAdmin(user) && (
                        <Grid item xs={12} md={6} lg={6}>
                            <MDBox mb={1.5} mt={1.5}>
                                <FormCard
                                    image="https://i0.wp.com/kapim.com.tr/wp-content/uploads/2022/03/Yeni-kapi-2-7.png"
                                    title="Hazır Bileşen Ekle"
                                    dateTime={
                                        <MDButton
                                            variant="gradient"
                                            color="dark"
                                            size="small"
                                            onClick={handleClickOpenSheetTemplates}
                                        >
                                            YENİ SET EKLE
                                        </MDButton>
                                    }
                                    color="dark"
                                />
                            </MDBox>
                        </Grid>
                    )}
                </Grid>
            </MDBox>
            {openTemplate && (
                <Template
                    open={openTemplate}
                    handleClose={handleCloseTemplate}
                    demandID={demand._id}
                    projectID={projectID}
                />
            )}
            {openSheetTemplates && (
                <SheetTemplates
                    open={openSheetTemplates}
                    handleClose={handleCloseSheetTemplates}
                    demandID={demand._id}
                    projectID={projectID}
                />
            )}
        </MDBox>
    );
}
