/* eslint-disable react/prop-types */

// core components
import Card from '@mui/material/Card';
// import CardBody from 'components/Card/CardBody.js';
import Tooltip from '@mui/material/Tooltip';

// core components

// import PersonIcon from '@mui/icons-material/Person';
import { deleteFile } from 'services/api/general/file';
// * Additional imports
import { formatDateMinutes } from 'helpers/smallCodes';

import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DWGIMG from 'assets/images/file/dwg.png';
import PDFIMG from 'assets/images/file/pdf.png';
import XLSIMG from 'assets/images/file/excel.png';
import ZIPIMG from 'assets/images/file/zip.png';
import MP4IMG from 'assets/images/file/mp4.png';
// Material Dashboard 2 PRO React components
import useDialogHook from 'hooks/useDialogHook';
import EditFile from 'components/General/File/EditFile.js';
export default function SingleAttachmentView(props) {
    const { attachment, setUpdateFiles, source } = props;
    const { open, handleClickOpen, handleClose } = useDialogHook();

    const attahcmentURL = attachment?.attachment?.fileLink;
    const checkFile = (url) => {
        let urlArray = Array.from(url);
        let lastFourChar = urlArray.slice(-4).join('').toLowerCase();

        if (lastFourChar == null) return url;

        const fileTypes = [
            { types: ['pdf', 'doc', 'docx'], img: PDFIMG },
            { types: ['m4a', 'mov', 'mp3', 'mp4'], img: MP4IMG },
            { types: ['cad', 'dwg', 'dxf'], img: DWGIMG },
            { types: ['csv', 'xlsx', 'xls'], img: XLSIMG },
            { types: ['zip', 'rar'], img: ZIPIMG },
        ];

        for (let fileType of fileTypes) {
            if (fileType.types.some((type) => lastFourChar.includes(type))) {
                return fileType.img;
            }
        }

        return url;
    };
    const deleteItem = () => {
        const confirmation = window.confirm('Emin Misiniz?');
        if (confirmation)
            deleteFile(attachment._id, source)
                .then(() => {
                    setUpdateFiles(Math.random());
                })
                .catch((er) => window.alert(er));
    };

    // Action buttons for the BookingCard
    const actionButtons = (
        <>
            <Tooltip title="Düzenle" placement="bottom">
                <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: 'pointer', mx: 3 }}>
                    <Icon color="info" onClick={handleClickOpen}>
                        edit
                    </Icon>
                </MDTypography>
            </Tooltip>
            <Tooltip title="Sil" placement="bottom">
                <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: 'pointer', mx: 3 }}>
                    <Icon color="primary" onClick={() => deleteItem()}>
                        delete
                    </Icon>
                </MDTypography>
            </Tooltip>
        </>
    );
    return (
        <Card
            sx={{
                '&:hover .card-header': {
                    transform: 'translate3d(0, -50px, 0)',
                },
            }}
        >
            <MDBox
                position="relative"
                borderRadius="lg"
                mt={-3}
                mx={0.5}
                className="card-header"
                sx={{
                    transition: 'transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)',
                }}
            >
                <a href={attahcmentURL} target="_blank" rel="noopener noreferrer">
                    <MDBox
                        component="img"
                        src={checkFile(attahcmentURL)}
                        alt={attachment.name}
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="100%"
                        position="relative"
                        zIndex={1}
                    />
                </a>
            </MDBox>
            <MDBox textAlign="center" pt={0.5} px={0.5}>
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={-8}>
                    {actionButtons}
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular" sx={{ mt: 4 }}>
                    {attachment.name}
                </MDTypography>
                <MDTypography variant="subtitle2" color="text" sx={{ mt: 0.5, mb: 0.5 }}>
                    {attachment.alt}
                </MDTypography>
            </MDBox>
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={0.5}
                pb={0.5}
                px={0.5}
                lineHeight={0.5}
            >
                <MDTypography variant="caption" fontWeight="lighter" color="text">
                    {formatDateMinutes(attachment.updatedAt)} <br />
                    {attachment?.user?.name}
                </MDTypography>
            </MDBox>
            {open && (
                <EditFile initial={attachment} open={open} handleClose={handleClose} setUpdateFiles={setUpdateFiles} />
            )}
        </Card>
    );
}
