import React from 'react';

import { useQuery } from 'react-query';
import AaroLoadingScreen from 'components/Loading';

import Card from '@mui/material/Card';
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDButton from "components/MDButton";
import { getSMS } from 'services/api/general/sms.js';

// Billing page components
import Transaction from 'components/General/SMS/SingleSMS';
import { formatDate } from 'helpers/dateHelpers';
const fetchSMS = async (query) => {
    const response = await getSMS(query)
        .then((res) => res?.data?.result)
        .catch((err) => err);
    return response;
};

export default function Anouncements(props) {
    const { relatedID, sourcePage = 'customer' } = props;

    const query = `relatedID=${relatedID}&sourcePage=${sourcePage}`;

    const { data, isLoading, error } = useQuery(
        ['fetchSms', query],
        () => fetchSMS(query),
        {
            staleTime: 91200000,
            enabled: !!relatedID,
        }
    );
    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    return (
        <Card sx={{ height: '100%' }}>
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}
                px={2}
            >
                <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                >
                    Gönderilen SMS'ler
                </MDTypography>
            </MDBox>
            <MDBox pt={3} pb={2} px={2}>
                <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: 'none' }}
                >
                    {data?.map((item, index) => {
                        const {
                            _id,
                            smsHeader,
                            smsContent,
                            smsStatusCode,
                            sentBy,
                            createdAt,
                            sentToPhone,
                        } = item;
                        const { colorBadge, iconBadge, message } = smsColor(
                            smsStatusCode?.substring(0, 2)
                        );
                        const { username } = sentBy;
                        return (
                            <Transaction
                                id={_id}
                                color={colorBadge}
                                icon={iconBadge}
                                name={smsHeader}
                                sentToPhone={sentToPhone}
                                description={smsContent}
                                date={formatDate(createdAt)}
                                netGsmResponse={message}
                                username={username}
                            />
                        );
                    })}
                </MDBox>
            </MDBox>
        </Card>
    );
}
const smsColor = (color) => {
    switch (color) {
        case '20':
            return {
                colorBadge: 'warning',
                iconBadge: 'priority_high',
                message:
                    'Mesaj metninde ki problemden dolayı gönderilemediğini veya standart maksimum mesaj karakter sayısını geçtiğini ifade eder. (Standart maksimum karakter sayısı 917 dir) Türkçe daha az.',
            };
        case '30':
            return {
                colorBadge: 'warning',
                iconBadge: 'priority_high',
                message: 'Geçersiz kullanıcı adı , şifre ',
            };
        case '40':
            return {
                colorBadge: 'warning',
                iconBadge: 'priority_high',
                message:
                    'Mesaj başlığınızın (gönderici adınızın) sistemde tanımlı olmadığını ifade eder. Gönderici adlarınızı API ile sorgulayarak kontrol edebilirsiniz.',
            };
        case '50':
            return {
                colorBadge: 'warning',
                iconBadge: 'priority_high',
                message:
                    'Abone hesabınız ile İYS kontrollü gönderimler yapılamamaktadır.',
            };
        case '51':
            return {
                colorBadge: 'warning',
                iconBadge: 'priority_high',
                message:
                    'Aboneliğinize tanımlı İYS Marka bilgisi bulunamadığını ifade eder.',
            };
        case '70':
            return {
                colorBadge: 'primary',
                iconBadge: 'priority_high',
                message:
                    'Hatalı sorgulama. Gönderdiğiniz parametrelerden birisi hatalı veya zorunlu alanlardan birinin eksik olduğunu ifade eder.',
            };
        case '85':
            return {
                colorBadge: 'error',
                iconBadge: 'priority_high',
                message:
                    "Mükerrer Gönderim sınır aşımı. Aynı numaraya 1 dakika içerisinde 20'den fazla görev oluşturulamaz.",
            };

        default:
            return {
                colorBadge: 'success',
                iconBadge: 'done_outline',
                message: 'Başarılı Sms Gönderimi',
            };
    }
};
