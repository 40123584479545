import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getSheetTemplates } from 'services/api/project';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import SheetTemplateCard from 'layouts/projects/Forms/SheetTemplates/SheetTemplateCard';
import SheetTemplateForm from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm';
import TemplateCrud from 'layouts/projects/Forms/SheetTemplates/SheetTemplateCRUD';
import AaroLoadingScreen from 'components/Loading';

const fetchSheetTemplates = async () => {
    try {
        const {
            data: { result },
        } = await getSheetTemplates();
        return result;
    } catch (error) {
        throw error;
    }
};

const isEmptyJson = (json) => Object.keys(json).length === 0 && json.constructor === Object;

function SheetTemplates({ open, handleClose, demandID }) {
    const [selected, setSelected] = useState({});
    const [edit, setEdit] = useState(false);

    const { data, isLoading, error } = useQuery('fetchSheetTemplates', fetchSheetTemplates, {
        staleTime: 600000,
    });

    if (isLoading) return <AaroLoadingScreen />;
    if (error) return `Bir Hata Oluştu: ${error.message}`;

    const user = JSON.parse(localStorage.getItem('user'));

    const groupByCategory = data.reduce((acc, cur) => {
        const { name } = cur.category;
        if (!acc[name]) acc[name] = [];
        acc[name].push(cur);
        return acc;
    }, {});

    const renderTemplateCategories = () =>
        Object.keys(groupByCategory).map((category) => (
            <MDBox key={category}>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                    <MDTypography variant="h6" fontWeight="medium">
                        {category}
                    </MDTypography>
                    <MDBox mb={1}>
                        <MDTypography variant="button" color="text">
                            {groupByCategory[category][0].categoryDescription}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <Grid container spacing={6}>
                    {groupByCategory[category].map((template) => (
                        <Grid key={template.id} item xs={12} md={6} xl={3} m={1}>
                            <SheetTemplateCard
                                key={template.id}
                                setSelected={setSelected}
                                sheetTemplate={template}
                                image={template.coverImage}
                                title={template.name}
                                description={template.description}
                                tags={template.tags}
                                color="dark"
                            />
                        </Grid>
                    ))}
                </Grid>
            </MDBox>
        ));

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
            <MDBox p={3}>
                {isEmptyJson(selected) ? (
                    renderTemplateCategories()
                ) : (
                    <SheetTemplateForm sheetTemplate={selected} demandID={demandID} handleClose={handleClose} />
                )}
                {isEmptyJson(selected) &&
                    user.role === 'superadmin' &&
                    (edit ? (
                        <TemplateCrud demandTemplates={data} />
                    ) : (
                        <MDButton onClick={() => setEdit(!edit)}>Düzenle</MDButton>
                    ))}
            </MDBox>
        </Dialog>
    );
}

export default SheetTemplates;
