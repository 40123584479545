

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

function SingleSMS({
	id,
	color,
	icon,
	name,
	description,
	date,
	sentToPhone,
	netGsmResponse,
	username,
}) {
	return (
		<MDBox key={id} component="li" py={1} pr={2} mb={1}>
			<MDBox display="flex" alignItems="center">
				<MDBox mr={2}>
					<MDButton
						variant="outlined"
						color={color}
						iconOnly
						circular
					>
						<Icon sx={{ fontWeight: 'bold' }}>{icon}</Icon>
					</MDButton>
				</MDBox>
				<MDBox display="flex" flexDirection="column">
					<MDTypography
						variant="button"
						fontWeight="medium"
						gutterBottom
					>
						{name}
					</MDTypography>
					<MDTypography
						variant="caption"
						color="h6"
						fontWeight="regular"
					>
						TEL: {sentToPhone}
					</MDTypography>
					<MDTypography
						variant="caption"
						color="h6"
						fontWeight="regular"
						mb={1}
						sx={{
							wordBreak: 'break-word',
						}}
					>
						MSJ: {description}
					</MDTypography>
					<MDTypography
						variant="caption"
						color="text"
						fontWeight="regular"
						sx={{
							wordBreak: 'break-word',
						}}
					>
						{date} - {username}{' '}
						{color !== 'success' && netGsmResponse}
					</MDTypography>
				</MDBox>
			</MDBox>
			{/* <MDTypography
					variant="button"
					color={color}
					fontWeight="medium"
					textGradient
				>
					{value}
				</MDTypography> */}
		</MDBox>
	);
}

// Typechecking props of the SingleSMS
SingleSMS.propTypes = {
	id: PropTypes.string.isRequired,
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'light',
		'dark',
	]).isRequired,
	icon: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	netGsmResponse: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
};

export default SingleSMS;
