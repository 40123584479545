import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import TemplateImage from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/TemplateImage';
import {
    KanatForm,
    KanatBadges,
} from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/KanatForm/index.js';
import { getSheetTemplate } from 'services/api/project';
import AaroLoadingScreen from 'components/Loading';
import { getParameters } from 'services/api/general/parameter';
import { parseStringNumber } from 'helpers/smallCodes';
import RetrievePriceAndNames from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/RetrievePriceAndNames';

const fetchSheetTemplate = async (id, filteredDataParameters) => {
    const response = await getSheetTemplate(id, filteredDataParameters)
        .then((rp) => rp.data.result)
        .catch((err) => err);
    return response;
};

const fetchParameters = async (id) => {
    const response = await getParameters(``).then((rp) => (rp.data?.result ? rp.data?.result : []));
    return response;
};

const transformDataToInitialAndMatches = (data, dataParameters, sheetName) => {
    const initial = {};
    const matches = {};
    if (data) {
        dataParameters.forEach((param) => {
            const key = param.key.split('_').slice(1).join('_'); // İlk "_" işaretinden sonrasını al
            const sheetKey = param.value;
            const sheetValue = data[`${sheetName}!${sheetKey}`]?.[0]?.[0]; // [["100"]] -> "100"
            if (sheetValue) {
                initial[key] = parseStringNumber(sheetValue);
                matches[key] = sheetName + '!' + sheetKey;
            }
        });
    }
    return { initial, matches };
};
function SheetTemplate({ sheetTemplate, demandID, handleClose }) {
    const [lock, setLock] = useState(false);

    const queryClient = useQueryClient();
    const {
        data: dataParameters,
        isLoading: isLoadingParameters,
        error: errorParameters,
    } = useQuery(['fetchParameters'], fetchParameters, { staleTime: 600000 });
    const sheetName = sheetTemplate.name;
    const filteredDataParameters =
        dataParameters?.filter((el) => el?.tag?.name === sheetTemplate.type)?.map((el) => sheetName + '!' + el.value) ||
        [];

    if (sheetTemplate.type === 'kanat') {
        const parameters = [
            'KANAT_MAMULLER_YUZEY_LISTE_FIYATI',
            'KANAT_MAMULLER_YUZEY_AD',
            'KANAT_MAMULLER_KANAT_LISTE_FIYATI',
            'KANAT_MAMULLER_KANAT_AD',
            'KANAT_MAMULLER_BOYALI_KANAT_LISTE_FIYATI',
            'KANAT_MAMULLER_BOYALI_KANAT_AD',
        ];

        parameters.forEach((param) => {
            filteredDataParameters.push(`${sheetName}!${param}`);
        });
    }
    if (sheetTemplate.type === 'kasa') {
        const parameters = [
            'KASA_MAMULLER_KASA_LISTE_FIYATI',
            'KASA_MAMULLER_KASA_AD',
            'KASA_MAMULLER_BOYALI_KASA_LISTE_FIYATI',
            'KASA_MAMULLER_BOYALI_KASA_AD',
        ];

        parameters.forEach((param) => {
            filteredDataParameters.push(`${sheetName}!${param}`);
        });
    }

    const { data, isLoading, error, refetch } = useQuery(
        ['fetchSheetTemplate', sheetTemplate._id, filteredDataParameters],
        () => fetchSheetTemplate(sheetTemplate._id, filteredDataParameters),
        {
            staleTime: 600000,
            enabled: !!dataParameters, // İlk sorgu sonuçlanmadan ikinci sorgu çalışmaz
        }
    );

    if (isLoadingParameters) return <AaroLoadingScreen />;

    if (errorParameters) return 'Bir Hata Oluştu: ' + errorParameters.message;

    if (isLoading) return <AaroLoadingScreen />;

    if (error) return 'Bir Hata Oluştu: ' + error.message;

    const { initial, matches } = transformDataToInitialAndMatches(data?.sheetData, dataParameters, sheetName);

    return (
        <MDBox py={3}>
            <Grid container justifyContent="center" sx={{ height: '100%', mt: 1 }}>
                <Grid item xs={12} md={4} lg={4}>
                    <MDBox p={3}>
                        <TemplateImage coverImage={sheetTemplate?.coverImage} sheetURL={sheetTemplate?.url} />
                        <KanatBadges initial={initial} />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <MDBox
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        height="100%"
                    >
                        <KanatForm
                            googleSheetURL={sheetTemplate.url}
                            initial={initial}
                            matches={matches}
                            refetch={refetch}
                            lock={lock}
                            setLock={setLock}
                        />
                        <RetrievePriceAndNames
                            queryClient={queryClient}
                            handleClose={handleClose}
                            demandID={demandID}
                            templateImage={sheetTemplate.coverImage}
                            type={sheetTemplate.type}
                            sheetName={sheetName}
                            sheetData={data?.sheetData}
                            lock={lock}
                            setLock={setLock}
                        />
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default SheetTemplate;
