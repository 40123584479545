import React, { useState, useMemo } from 'react';
import { config } from 'Constants';
import { Formik, Form, Field, useField } from 'formik';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { postSheetTemplate, updateSheetTemplate, deleteSheetTemplate } from 'services/api/project';
import { notify } from 'helpers/notificationHelper';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getTags } from 'services/api/general/tag';

const { DEMAND_TEMPLATE_CATEGORY_ID, DEMAND_TEMPLATE_PRODUCT_ID } = config.fixedID;

const fetchTags = async (id) => {
    const response = await getTags(id);
    return response.data?.result?.tags || [];
};

const DataTable = ({ demandTemplates }) => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const queryClient = useQueryClient();

    const {
        data: tags,
        isLoading,
        error,
    } = useQuery(['fetchTagGroups', DEMAND_TEMPLATE_CATEGORY_ID], () => fetchTags(DEMAND_TEMPLATE_CATEGORY_ID), {
        staleTime: 600000,
    });

    const {
        data: doorTags,
        isLoading: isLoadingDoorTags,
        error: errorDoorTags,
    } = useQuery(['fetchTagGroups', DEMAND_TEMPLATE_PRODUCT_ID], () => fetchTags(DEMAND_TEMPLATE_PRODUCT_ID), {
        staleTime: 600000,
    });

    const updateMutation = useMutation(
        (data) =>
            updateSheetTemplate(data._id, data.values)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message)),
        {
            onSuccess: () => queryClient.invalidateQueries(['fetchSheetTemplates']),
        }
    );

    const createMutation = useMutation(
        (newCategory) =>
            postSheetTemplate(newCategory)
                .then((rp) => {
                    notify(rp.data.success, rp.data.message);
                    return rp;
                })
                .catch((e) => notify(false, e.message)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['fetchSheetTemplates']);
                setOpen(false);
            },
        }
    );

    const deleteMutation = useMutation(
        (id) => {
            if (window.confirm('Silmek İstediğinize Emin Misiniz?')) {
                return deleteSheetTemplate(id)
                    .then((rp) => {
                        notify(rp.data.success, rp.data.message);
                        return rp;
                    })
                    .catch((e) => notify(false, e.message));
            }
        },
        {
            onSuccess: () => queryClient.invalidateQueries(['fetchSheetTemplates']),
        }
    );

    const formikInitialValues = {
        category: '',
        name: '',
        type: '',
        description: '',
        coverImage: '',
        url: '',
        tags: [''],
    };

    const formikEditInitialValues = useMemo(() => {
        if (!selectedRow) return formikInitialValues;

        return {
            category: selectedRow.category,
            name: selectedRow.name,
            type: selectedRow.type,
            description: selectedRow.description,
            coverImage: selectedRow.coverImage,
            url: selectedRow.url,
            tags: selectedRow.tags,
        };
    }, [selectedRow]);

    const handleFormSubmit = (values, actions) => {
        createMutation.mutate(values);
        actions.resetForm();
    };

    const handleEditFormSubmit = (values) => {
        updateMutation.mutate({ _id: selectedRow._id, values });
        setEditOpen(false);
    };

    const handleEditClick = (rowId) => {
        const row = demandTemplates.find((template) => template._id === rowId);
        setSelectedRow(row);
        setEditOpen(true);
    };

    const getCategoryNameById = (categoryId) => {
        const tag = tags?.find((tag) => tag._id === categoryId._id);
        return tag ? tag.name : '';
    };

    const rows = demandTemplates.map((template) => ({
        id: template._id,
        category: template.category,
        name: template.name,
        type: template.type,
        description: template.description,
        coverImage: template.coverImage,
        url: template.url,
        tags: template.tags,
    }));

    const SelectMultiple = ({ label, ...props }) => {
        const [field, , helpers] = useField(props);
        const { value } = field;
        const { setValue } = helpers;

        const handleChange = (event) => {
            setValue(Array.from(event.target.selectedOptions, (option) => option.value));
        };

        return (
            <div>
                <label>{label}</label>
                <select {...field} {...props} multiple value={value} onChange={handleChange}>
                    {props.children}
                </select>
            </div>
        );
    };

    return (
        <MDBox color="white" bgColor="white" variant="gradient" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <MDButton variant="gradient" color="success" size="small" onClick={() => setOpen(true)}>
                Yeni Sheet Şablonu Ekle
            </MDButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Yeni Sheet Şablonu Ekle</DialogTitle>
                <DialogContent>
                    <DialogContentText>Lütfen yeni şablon için bir kategori ve kapı ismi girin.</DialogContentText>
                    {isLoading && <DialogContentText>Tag'ler yükleniyor...</DialogContentText>}
                    {error && <DialogContentText>Hata: {error.message}</DialogContentText>}
                    {isLoadingDoorTags && <DialogContentText>Tag'ler yükleniyor...</DialogContentText>}
                    {errorDoorTags && <DialogContentText>Hata: {errorDoorTags.message}</DialogContentText>}
                    <Formik initialValues={formikInitialValues} onSubmit={handleFormSubmit}>
                        <Form>
                            {!isLoading && !error && (
                                <Field as="select" name="category">
                                    <option disabled value="">
                                        Kategori Seçin
                                    </option>
                                    {tags?.map((tag) => (
                                        <option key={tag._id} value={tag._id}>
                                            {tag.name}
                                        </option>
                                    ))}
                                </Field>
                            )}
                            <Field name="name" type="text" placeholder="Kapı İsmi" />
                            <Field name="type" type="text" placeholder="grubu (kanat, uKasa..)" />
                            <Field name="description" type="text" placeholder="Kapı Açıklaması" />
                            <Field name="coverImage" type="text" placeholder="Kapı Resmi" />
                            <Field name="url" type="text" placeholder="Google Sheet URL" />
                            {!isLoadingDoorTags && !errorDoorTags && (
                                <SelectMultiple name="tags">
                                    <option disabled value="">
                                        Etiket Seçin
                                    </option>
                                    {doorTags?.map((tag) => (
                                        <option key={tag._id} value={tag._id}>
                                            {tag.name}
                                        </option>
                                    ))}
                                </SelectMultiple>
                            )}
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>İptal</Button>
                                <Button type="submit">Ekle</Button>
                            </DialogActions>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
            <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
                <DialogTitle>Sheet Şablonunu Düzenle</DialogTitle>
                <DialogContent>
                    {isLoading && <DialogContentText>Tag'ler yükleniyor...</DialogContentText>}
                    {error && <DialogContentText>Hata: {error.message}</DialogContentText>}
                    {isLoadingDoorTags && <DialogContentText>Tag'ler yükleniyor...</DialogContentText>}
                    {errorDoorTags && <DialogContentText>Hata: {errorDoorTags.message}</DialogContentText>}
                    <Formik initialValues={formikEditInitialValues} onSubmit={handleEditFormSubmit}>
                        <Form>
                            <Field as="select" name="category">
                                <option disabled value="">
                                    Kategori Seçin
                                </option>
                                {tags?.map((tag) => (
                                    <option key={tag._id} value={tag._id}>
                                        {tag.name}
                                    </option>
                                ))}
                            </Field>
                            <Field name="name" type="text" placeholder="Kapı İsmi" />
                            <Field name="type" type="text" placeholder="grubu (kanat, uKasa..)" />
                            <Field name="description" type="text" placeholder="Kapı Açıklaması" />
                            <Field name="coverImage" type="text" placeholder="Kapı Resmi" />
                            <Field name="url" type="text" placeholder="Google Sheet URL" />
                            {!isLoadingDoorTags && !errorDoorTags && (
                                <SelectMultiple name="tags">
                                    <option disabled value="">
                                        Etiket Seçin
                                    </option>
                                    {doorTags?.map((tag) => (
                                        <option key={tag._id} value={tag._id}>
                                            {tag.name}
                                        </option>
                                    ))}
                                </SelectMultiple>
                            )}
                            <DialogActions>
                                <Button onClick={() => setEditOpen(false)}>İptal</Button>
                                <Button type="submit">Güncelle</Button>
                            </DialogActions>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
            <DataGridPro
                rows={rows}
                autoHeight
                autoPageSize
                columns={[
                    {
                        field: 'category',
                        flex: 1,
                        renderCell: (params) => <div>{getCategoryNameById(params.value)}</div>,
                        editable: false,
                    },
                    { field: 'name', editable: false, flex: 1 },
                    { field: 'type', editable: false, flex: 1 },
                    { field: 'description', editable: false, flex: 1 },
                    { field: 'coverImage', editable: false, flex: 1 },
                    { field: 'url', editable: false, flex: 1 },
                    {
                        flex: 0.1,
                        field: 'duzenle',
                        headerName: 'Düzenle',
                        sortable: false,
                        width: 100,
                        renderCell: (params) => (
                            <IconButton aria-label="edit" onClick={() => handleEditClick(params.id)}>
                                <EditIcon />
                            </IconButton>
                        ),
                    },
                    {
                        flex: 0.1,
                        field: 'delete',
                        headerName: 'Sil',
                        sortable: false,
                        width: 100,
                        renderCell: (params) => (
                            <IconButton aria-label="delete" onClick={() => deleteMutation.mutate(params.id)}>
                                <DeleteIcon />
                            </IconButton>
                        ),
                    },
                ]}
            />
        </MDBox>
    );
};

export default DataTable;
