// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { postDiger } from 'services/api/form';
import { parseStringNumber } from 'helpers/smallCodes';
import { config } from 'Constants';
import { styled } from '@mui/material/styles';

const HoverableBox = styled(MDBox)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.8,
        boxShadow: theme.shadows[4],
    },
}));

const saveSelection = (
    baslik,
    ekNot,
    verilenFiyat,
    miktar,
    lock,
    setLock,
    templateImage,
    demandID,
    queryClient,
    handleClose
) => {
    setLock(!lock);

    const values = {
        baslik: baslik,
        gorsel: templateImage,
        ekNot: ekNot,
        miktar: parseStringNumber(miktar),
        yoneticiNotu: 'OTO SHEET',
        verilenFiyat: parseStringNumber(verilenFiyat),
        durumu: config?.demandStatusID?.fiyatVerildi,
    };

    postDiger(demandID, values)
        .then(() => {
            queryClient.invalidateQueries(['fetchDemand', demandID]);
            handleClose();
        })
        .catch((err) => console.log(err));
};

function PriceCard({
    color,
    icon,
    baslik,
    ekNot,
    verilenFiyat,
    valueColor,
    miktar,
    lock,
    setLock,
    templateImage,
    demandID,
    queryClient,
    handleClose,
}) {
    return (
        <MDBox flex={1} display="flex" justifyContent="center" m={1} shadow="lg" borderRadius="lg">
            <Card
                sx={{
                    width: '100%',
                    minHeight: '370px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    opacity: lock ? 0.6 : 1,
                    pointerEvents: lock ? 'none' : 'auto',
                    cursor: lock ? 'not-allowed' : 'pointer',
                }}
            >
                <MDBox p={2} mx={3} display="flex" justifyContent="center">
                    <HoverableBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor={color}
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                        onClick={() =>
                            saveSelection(
                                ekNot,
                                baslik,
                                verilenFiyat,
                                miktar,
                                lock,
                                setLock,
                                templateImage,
                                demandID,
                                queryClient,
                                handleClose
                            )
                        }
                    >
                        <Icon fontSize="default">{icon}</Icon>
                    </HoverableBox>
                </MDBox>
                <MDBox px={2} textAlign="center" lineHeight={1.25} flexGrow={1} display="flex" justifyContent="center">
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {baslik}
                    </MDTypography>
                </MDBox>
                <MDBox px={2} textAlign="center" lineHeight={1} flexGrow={1} display="flex" flexDirection="column">
                    {ekNot && (
                        <MDBox mt={1} mb={2}>
                            <MDTypography variant="caption" color="text" fontWeight="regular">
                                {ekNot}
                            </MDTypography>
                        </MDBox>
                    )}
                </MDBox>
                <MDBox pb={2} px={2} textAlign="center">
                    {ekNot && !verilenFiyat ? null : <Divider />}
                    {verilenFiyat && (
                        <MDBox mt={1}>
                            <MDTypography variant="h5" fontWeight="medium" color={valueColor}>
                                {verilenFiyat}₺
                            </MDTypography>
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        </MDBox>
    );
}

export default PriceCard;
