import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import chatBackground from 'assets/images/chatBackground.png';

import PrimaryMessage from './PrimaryMessage';
import SecondaryMessage from './SecondaryMessage';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

const useStyles = makeStyles({
    messageArea: {
        maxHeight: '60vh',
        overflowY: 'auto',
        backgroundImage: `url(${chatBackground})`,
    },

    gridSelf: {
        backgroundColor: '#dcf8c6',
        borderRadius: '0.5rem',
    },

    gridOther: {
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
    },
});

export default function DisplayMessages(props) {
    const { messages, scrollRef, setRefreshChat, source } = props;
    const user = JSON.parse(localStorage.getItem('user'));
    const classes = useStyles();

    const isSameUser = (username1, username2) => username1 === username2;

    const fillMessage = (arr) => {
        const views = [];

        arr.forEach((el) =>
            views.push(
                <MDBox p={1} key={el._id}>
                    <ListItem key="1">
                        <Grid
                            container
                            className={el?.user?.username === user?.username ? classes.gridSelf : classes.gridOther}
                        >
                            <Grid item xs={12}>
                                <ListItemText
                                    ref={scrollRef}
                                    className={classes.message}
                                    align={el?.user?.username === user?.username ? 'right' : 'left'}
                                    primary={
                                        <PrimaryMessage
                                            message={el.message}
                                            messageID={el._id}
                                            isSameUser={isSameUser(el?.user?.username, user?.username)}
                                            setRefreshChat={setRefreshChat}
                                            source={source}
                                        />
                                    }
                                    secondary={
                                        <SecondaryMessage
                                            message={el.message}
                                            updatedAt={el.updatedAt}
                                            messageUser={el.user}
                                            isSameUser={isSameUser(el?.user?.username, user?.username)}
                                        />
                                    }
                                ></ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>
                </MDBox>
            )
        );
        return views.map((el) => el);
    };
    return (
        <List
            sx={{
                maxHeight: '49vh',
                overflowY: 'auto',
                backgroundImage: `url(${chatBackground})`,
                borderRadius: '0.75rem 0.75rem 0 0',
            }}
        >
            {messages ? fillMessage(messages) : ''}
        </List>
    );
}
