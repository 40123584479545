import React from 'react';
import { Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import PriceCard from 'layouts/projects/Forms/SheetTemplates/SheetTemplateForm/components/RetrievePriceAndNames/PriceCard';

const getSheetDataValue = (sheetData, sheetName, key) => sheetData[sheetName + '!' + key]?.[0]?.[0];

const RetrievePriceAndNames = ({
    queryClient,
    handleClose,
    demandID,
    templateImage,
    type,
    sheetName,
    sheetData,
    lock,
    setLock,
}) => {
    if (type === 'kanat') {
        const miktar = getSheetDataValue(sheetData, sheetName, 'KANAT_MIKTAR');
        const yuzeyAdi = getSheetDataValue(sheetData, sheetName, 'KANAT_MAMULLER_YUZEY_AD');
        const yuzeyFiyati = getSheetDataValue(sheetData, sheetName, 'KANAT_MAMULLER_YUZEY_LISTE_FIYATI');
        const hamKanatAdi = getSheetDataValue(sheetData, sheetName, 'KANAT_MAMULLER_KANAT_AD');
        const hamKanatFiyati = getSheetDataValue(sheetData, sheetName, 'KANAT_MAMULLER_KANAT_LISTE_FIYATI');
        const boyaliKanatAdi = getSheetDataValue(sheetData, sheetName, 'KANAT_MAMULLER_BOYALI_KANAT_AD');
        const boyaliKanatFiyati = getSheetDataValue(sheetData, sheetName, 'KANAT_MAMULLER_BOYALI_KANAT_LISTE_FIYATI');

        const buttonConfigs = [
            { label: '(2) HAM YUZEY', name: yuzeyAdi, price: yuzeyFiyati, amount: miktar * 2, color: 'success' },
            { label: '(1) HAM KANAT', name: hamKanatAdi, price: hamKanatFiyati, amount: miktar, color: 'info' },
            {
                label: '(1) BOYALI KANAT',
                name: boyaliKanatAdi,
                price: boyaliKanatFiyati,
                amount: miktar,
                color: 'dark',
            },
        ];

        return (
            <Grid container spacing={3}>
                {buttonConfigs.map((config, index) => (
                    <Grid item xs={12} sm={12} md={4} key={index}>
                        <PriceCard
                            icon="add"
                            baslik={config.label}
                            ekNot={config.name}
                            verilenFiyat={config.price}
                            miktar={config.amount}
                            color={config.color}
                            queryClient={queryClient}
                            handleClose={handleClose}
                            demandID={demandID}
                            templateImage={templateImage}
                            type={type}
                            sheetName={sheetName}
                            sheetData={sheetData}
                            lock={lock}
                            setLock={setLock}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }

    if (type === 'kasa') {
        const kasaConfigs = [
            { label: '(1) Kasa Ham', price: '1924,42₺', color: 'info' },
            { label: '(1) Kasa Boyalı', price: '2924,42₺', color: 'dark' },
        ];

        return (
            <Grid container spacing={3}>
                {kasaConfigs.map((config, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                        <MDButton color={config.color} variant="gradient" fullWidth>
                            {config.label}: {config.price}
                        </MDButton>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return null;
};

export default RetrievePriceAndNames;
