import React from 'react';
import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import MDButton from 'components/MDButton';
import { updateGoogleSheet } from 'services/api/project';
import Grid from '@mui/material/Grid';
import FormSelect from 'layouts/projects/Forms/FormComponents/FormSelect';
import FormSwitch from 'layouts/projects/Forms/FormComponents/FormSwitch';
import OutlinedFormTextField from 'layouts/projects/Forms/FormComponents/OutlinedFormTextField';
import { validationSchemaKanatSheetTemplate } from 'layouts/projects/Forms/FormComponents/YupControlSchema';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';
import { kanatToSheet, sheetToKanat } from '@samilkahraman/demand-namer';

const useStyles = makeStyles((theme) => ({
    switch: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
}));

export const KanatForm = (props) => {
    const { initial, setOpen, googleSheetURL, matches, refetch, lock, setLock } = props;

    const classes = useStyles();
    const allowedChanges = [
        'miktar',
        'en',
        'boy',
        'cumbaBandi',
        'cumbaTipi',
        'menteseYeri',
        'camYeri',
        'kilitYeri',
        'hidrolik',
        'giyotin',
        'durbun',
    ];
    const isFieldDisabled = (fieldName) => !allowedChanges.includes(fieldName);

    // const toBadge = (degisken, aciklama, renk = 'dark') => {
    //     return (
    //         <MDBox>
    //             <MDBadge
    //                 m={1}
    //                 badgeContent={`${aciklama}: ${formik.values[degisken] ? formik.values[degisken] : '-'}`}
    //                 size="xs"
    //                 container
    //                 variant="gradient"
    //                 color={renk}
    //             />
    //         </MDBox>
    //     );
    // };
    const formik = useFormik({
        initialValues: sheetToKanat(initial),
        validationSchema: validationSchemaKanatSheetTemplate,
        onSubmit: async (values) => {
            setLock(!lock);
            const sheetData = kanatToSheet(values);

            // Create an array of key-value pairs for allowed and changed values
            const updates = Object.keys(sheetData).reduce((acc, key) => {
                if (allowedChanges.includes(key) && initial[key] !== sheetData[key] && matches[key]) {
                    acc.push({ range: matches[key], values: [[sheetData[key]]] });
                }
                return acc;
            }, []);
            if (updates.length === 0) {
                setLock(lock);
                setOpen(false);
                return;
            }

            try {
                // Send all updates to the Google Sheet
                await updateGoogleSheet(googleSheetURL, updates);
                refetch().then(() => {
                    setLock(lock);
                });

                // queryClient.invalidateQueries(['fetchSheetTemplate']);
                setOpen(false);
            } catch (err) {
                console.log(err);
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid container spacing={3}>
                    {/* DERZ - CUMBA*/}
                    <Grid item xs={12} sm={12} md={12}>
                        {/* Miktar - Kalınlık - En - Boy */}
                        <Grid container justifyContent="space-between" spacing={3}>
                            {/* Miktar */}
                            <Grid item xs={12} sm={12} md={3}>
                                <OutlinedFormTextField
                                    itemID="miktar"
                                    label="Miktar (Adet)"
                                    placeHolder="Örn: 1000"
                                    type="number"
                                    formik={formik}
                                    step="1"
                                    adornment="AD"
                                    disabled={isFieldDisabled('miktar')}
                                />
                            </Grid>

                            {/* En */}
                            <Grid item xs={12} sm={12} md={3}>
                                <OutlinedFormTextField
                                    itemID="en"
                                    label="En"
                                    placeHolder="Örn: 82"
                                    type="number"
                                    formik={formik}
                                    step="0.1"
                                    adornment="CM"
                                    disabled={isFieldDisabled('en')}
                                />
                            </Grid>
                            {/* Boy */}
                            <Grid item xs={12} sm={12} md={3}>
                                <OutlinedFormTextField
                                    itemID="boy"
                                    label="Boy"
                                    placeHolder="Örn: 209"
                                    type="number"
                                    formik={formik}
                                    step="0.1"
                                    adornment="CM"
                                    disabled={isFieldDisabled('boy')}
                                />
                            </Grid>
                            {/* cumbaBandi */}
                            <Grid item xs={12} sm={12} md={3}>
                                <FormSelect
                                    itemID="cumbaBandi"
                                    formik={formik}
                                    inputName="Cumba Bandı"
                                    items={[
                                        {
                                            name: 'Yok',
                                            value: 'Yok',
                                        },
                                        {
                                            name: '2 Kenar',
                                            value: '2 Kenar',
                                        },
                                        {
                                            name: '4 Kenar',
                                            value: '4 Kenar',
                                        },
                                    ]}
                                    helperText={'Kanat Cumba Bandı'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" spacing={3}>
                            <Grid item xs={12} sm={12} md={4}>
                                {/* menteseYeri */}
                                <FormSelect
                                    itemID="menteseYeri"
                                    formik={formik}
                                    inputName="Menteşe Yeri"
                                    items={[
                                        {
                                            name: 'Yok',
                                            value: 'Yok',
                                        },
                                        {
                                            name: 'Yönlü 2',
                                            value: 'Yönlü 2',
                                        },
                                        {
                                            name: 'Yönlü 3',
                                            value: 'Yönlü 3',
                                        },
                                        {
                                            name: 'Gömme 2',
                                            value: 'Gömme 2',
                                        },
                                        {
                                            name: 'Gömme 3',
                                            value: 'Gömme 3',
                                        },
                                    ]}
                                    helperText={'Menteşe Yeri'}
                                    disabled={isFieldDisabled('menteseYeri')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                {' '}
                                <FormSelect
                                    itemID="camYeri"
                                    formik={formik}
                                    inputName="Cam Yeri"
                                    items={[
                                        {
                                            name: 'Yok',
                                            value: 'Yok',
                                        },
                                        {
                                            name: '1 Göbek Serenli',
                                            value: '1 Göbek Serenli',
                                        },
                                        {
                                            name: '3 Göbek Serenli',
                                            value: '3 Göbek Serenli',
                                        },
                                        {
                                            name: '5 Göbek Serenli',
                                            value: '5 Göbek Serenli',
                                        },
                                        {
                                            name: '1 Göbek MDFli',
                                            value: '1 Göbek MDFli',
                                        },
                                        {
                                            name: '3 Göbek MDFli',
                                            value: '3 Göbek MDFli',
                                        },
                                        {
                                            name: '5 Göbek MDFli',
                                            value: '5 Göbek MDFli',
                                        },
                                        {
                                            name: 'Giyotin',
                                            value: 'Giyotin',
                                        },
                                    ]}
                                    helperText={'Cam Yeri'}
                                    disabled={isFieldDisabled('camYeri')}
                                />{' '}
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                {' '}
                                {/* kilitYeri */}
                                <FormSelect
                                    itemID="kilitYeri"
                                    formik={formik}
                                    inputName="Kilit Yeri"
                                    items={[
                                        {
                                            name: 'Yok',
                                            value: 'Yok',
                                        },
                                        {
                                            name: 'Standart Kale',
                                            value: 'Standart Kale',
                                        },
                                        {
                                            name: 'Topuz',
                                            value: 'Topuz',
                                        },
                                        {
                                            name: 'Otel Tipi',
                                            value: 'Otel Tipi',
                                        },
                                    ]}
                                    helperText={'Kilit Yeri'}
                                    disabled={isFieldDisabled('kilitYeri')}
                                />{' '}
                            </Grid>
                        </Grid>
                        {/* Var Yok Seçenekleri */}
                        <Grid container className={classes.switch}>
                            {/* Hidrolik Yeri*/}
                            <FormSwitch
                                formik={formik}
                                name="hidrolik"
                                color="secondary"
                                label="Hidrolik Yeri"
                                disabled={isFieldDisabled('hidrolik')}
                            />
                            {/*Giyotin Kesim*/}
                            <FormSwitch
                                formik={formik}
                                name="giyotin"
                                color="secondary"
                                label="Giyotin Kesim"
                                disabled={isFieldDisabled('giyotin')}
                            />
                            {/*Dürbün Yeri*/}
                            <FormSwitch
                                formik={formik}
                                name="durbun"
                                color="secondary"
                                label="Dürbün Yeri"
                                disabled={isFieldDisabled('durbun')}
                            />
                            {/* Sert Ağaç Dönülmesi*/}
                            <FormSwitch
                                formik={formik}
                                name="sertAgacDonme"
                                color="secondary"
                                label="Sert Ağaç Dönülmesi"
                                disabled={isFieldDisabled('sertAgacDonme')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <MDButton color="warning" variant="gradient" fullWidth type="submit" disabled={lock}>
                                Hesapla
                            </MDButton>
                        </Grid>
                        <br />
                        {/* Derz */}
                        {/* <Grid container justifyContent="space-between" spacing={3}>
                            <Grid item xs={12}>
                                <MDBox display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                                    {toBadge('kalinlik', 'KALINLIK: ', 'secondary')}
                                    {toBadge('derzSekli', 'DERZ ŞEKLİ: ', 'secondary')}
                                    {toBadge('derzTipi', 'DERZ TİPİ: ', 'secondary')}
                                    {toBadge('derzAdedi', 'DERZ ADEDİ: ', 'secondary')}
                                    {toBadge('derzBicakAdedi', 'DERZ BIÇAK ADEDİ: ', 'secondary')}
                                    {toBadge('maxDerzDerinligi', 'Max Derz Derinligi (CM): ', 'secondary')}
                                    {toBadge('cumbaTipi', 'Cumba TİPİ: ', 'secondary')}
                                    {toBadge('cumbaKalinligi', 'Cumba Kalınlığı (MM): ', 'secondary')}
                                    {toBadge('cins', 'CİNS: ', 'secondary')}
                                    {toBadge('cinsAciklama', 'CİNS Açıklaması: ', 'secondary')}
                                    {toBadge('dolguTipi', 'Dolgu TİPİ: ', 'secondary')}
                                    {toBadge('mdfYuzeyKalinlik', 'MDF Yüzey Kalınlığı (CM): ', 'secondary')}
                                    {toBadge('ekNot', 'NOTLAR: ', 'dark')}
                                </MDBox>
                            </Grid>
                        </Grid> */}

                        {/* Cins - derzSekli -menteseYeri*/}
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export const KanatBadges = (props) => {
    const { initial } = props;
    const values = sheetToKanat(initial);
    const toBadge = (degisken, aciklama, renk = 'dark') => {
        return (
            <MDBox>
                <MDBadge
                    m={1}
                    badgeContent={`${aciklama}: ${values[degisken] ? values[degisken] : '-'}`}
                    size="xs"
                    container
                    variant="gradient"
                    color={renk}
                />
            </MDBox>
        );
    };
    return (
        <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12}>
                <MDBox display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                    {toBadge('kalinlik', 'KALINLIK: ', 'secondary')}
                    {toBadge('derzSekli', 'DERZ ŞEKLİ: ', 'secondary')}
                    {toBadge('derzTipi', 'DERZ TİPİ: ', 'secondary')}
                    {toBadge('derzAdedi', 'DERZ ADEDİ: ', 'secondary')}
                    {toBadge('derzBicakAdedi', 'DERZ BIÇAK ADEDİ: ', 'secondary')}
                    {toBadge('maxDerzDerinligi', 'Max Derz Derinligi (CM): ', 'secondary')}
                    {toBadge('cumbaTipi', 'Cumba TİPİ: ', 'secondary')}
                    {toBadge('cumbaKalinligi', 'Cumba Kalınlığı (MM): ', 'secondary')}
                    {toBadge('cins', 'CİNS: ', 'secondary')}
                    {toBadge('cinsAciklama', 'CİNS Açıklaması: ', 'secondary')}
                    {toBadge('dolguTipi', 'Dolgu TİPİ: ', 'secondary')}
                    {toBadge('mdfYuzeyKalinlik', 'MDF Yüzey Kalınlığı (CM): ', 'secondary')}
                    {toBadge('ekNot', 'NOTLAR: ', 'dark')}
                </MDBox>
            </Grid>
        </Grid>
    );
};
