import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import { formatDateMinutes } from 'helpers/smallCodes';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import MDBox from 'components/MDBox';

const useStyles = makeStyles({
	message: {
		color: '#263238',
		padding: '7px 7px 3px 7px',
		margin: '0px',
		wordWrap: 'break-word',
	},

	profilePicture: {
		color: 'transparent',
		width: '1rem',
		height: '1rem',
		objectFit: 'cover',
		textAlign: 'center',
		textIndent: '10000px',
	},
	messageDetails: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '0.2rem',
	},
	deletedMessageBlockRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		gap: '0.3rem',
		marginRight: '0.4rem',
	},

	deletedMessage: {
		fontSize: '0.7rem',
		color: 'rgba(0, 0, 0, 0.35)',
		fontStyle: 'italic',
	},

	avatar: { width: '1rem', height: '1rem', marginLeft: '0.2rem' },
});

const secondaryStyle = {
	color: '#263238',
	fontSize: '0.7rem',
	fontWeight: '100',
	lineHeight: '1.43',
	marginRight: '0.4rem',
};
export default function PrimaryMessage(props) {
	const { message, updatedAt, messageUser, isSameUser } = props;
	const user = JSON.parse(localStorage.getItem('user'));
	const classes = useStyles();

	const isEmptyMessage = (message) => message.length === 0;

	if (isEmptyMessage(message))
		return isSameUser ? (
			<div className={classes.deletedMessageBlockRight}>
				<div className={classes.deletedMessage}>
					{formatDateMinutes(updatedAt)}
				</div>
			</div>
		) : (
			<div className={classes.deletedMessageBlockRight}>
				<div className={classes.deletedMessage}>
					{messageUser?.name + ' - ' + formatDateMinutes(updatedAt)}
				</div>
			</div>
		);

	return isSameUser ? (
		<MDBox mr={2}>
			<Typography type="body2" style={secondaryStyle}>
				{formatDateMinutes(updatedAt)}
			</Typography>
		</MDBox>
	) : (
		<MDBox ml={2}>
			<div className={classes.messageDetails}>
				<div>
					<Avatar className={classes.avatar}>
						{messageUser?.profilePicture ? (
							<img
								src={messageUser.profilePicture}
								alt="..."
								className={classes.profilePicture}
							/>
						) : (
							<PersonIcon />
						)}
					</Avatar>
				</div>
				<div>
					<Typography type="body2" style={secondaryStyle}>
						{messageUser?.name +
							' - ' +
							formatDateMinutes(updatedAt)}
					</Typography>
				</div>
			</div>
		</MDBox>
	);
}
